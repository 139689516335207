@font-face {
    font-family: 'Alte Haas Grotesk';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/altehaasgroteskregular.woff2') format('woff2'),
         url('./fonts/AlteHaasGroteskRegular.woff') format('woff');
}

@font-face {
    font-family: 'Alte Haas Grotesk';
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/altehaasgroteskbold.woff2') format('woff2'),
         url('./fonts/AlteHaasGroteskBold.woff') format('woff');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: black;
  /* background-color: rgb(193,193,193); */
  background-color: white;
}

.slick-prev:before {
  content: '';
}

.slick-next:before {
  content: '';
}

.slick-slide {
  height: auto;
  max-height: 500px;
  width: 100%;
  margin: auto;
}


h1 {
  text-transform: uppercase;
}

a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: underline;
}

table, th, td {
  border: 1px solid rgb(53,53,53);
  border-collapse: collapse;
}
th, td {
  padding: 2px 5px;
}
/* 
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
} */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}